import Head from 'next/head';

const Header = (props) => {
  return (
    <Head>
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="viewport" content="width=device-width" />
      <title>unalanu</title>
      {/* {process.env.NEXT_PUBLIC_ENV == "production" && <link rel="manifest" href="/manifest.json" />} */}
      <link rel="manifest" href="/manifest.json" />
      <meta name="application-name" content="unalanu" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content="unalanu" />
      <meta name="description" content="Activities you like, with people like you" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="msapplication-config" content="/icons/browserconfig.xml" />
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta name="msapplication-tap-highlight" content="no" />
      <meta name="theme-color" content="#FFFFFF" />

      <link rel="apple-touch-icon" href="/icons/ios/512.png" />
      <link rel="apple-touch-icon" sizes="152x152" href="/icons/ios/152.png" />
      <link rel="apple-touch-icon" sizes="180x180" href="/icons/ios/180.png" />
      <link rel="apple-touch-icon" sizes="167x167" href="/icons/ios/167.png" />

      <meta property="og:type" content="website" />
      <meta property="og:title" content="unalanu" />
      <meta property="og:description" content="Activities you like, with people like you" />
      <meta property="og:site_name" content="unalanu" />
    </Head>
  );
};

export default Header;
