
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import Layout from "../components/layouts/layout";
import "../styles/app.scss";
import { SessionProvider } from "next-auth/react";
import { Hydrate, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ActionCableProvider } from "../src/providers/action_cable_context";
import { useEffect, useState, Suspense } from "react";
import { init } from "@socialgouv/matomo-next";
import dynamic from "next/dynamic";
const CookieConsent = dynamic(() => import("../components/shared/cookie_consent"), { ssr: false });
const MobileAppHandler = dynamic(() => import("../src/mobile/mobile_app_handler"), { ssr: false });
const MATOMO_URL = process.env.NEXT_PUBLIC_MATOMO_URL;
const MATOMO_SITE_ID = process.env.NEXT_PUBLIC_MATOMO_SITE_ID;
function MyApp({ Component, pageProps: { session, ...pageProps } }) {
    useEffect(() => {
        init({ url: MATOMO_URL, siteId: MATOMO_SITE_ID });
    }, []);
    useEffect(() => {
        import("bootstrap/dist/js/bootstrap.bundle");
    }, []);
    const [queryClient] = useState(() => new QueryClient({
        defaultOptions: {
            queries: {
                retry: false
            }
        }
    }));
    return (<QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <SessionProvider session={session} refetchOnWindowFocus={true}>
          <ActionCableProvider>
            <Layout>
              <Component {...pageProps}/>
              <CookieConsent />
              <MobileAppHandler />
            </Layout>
          </ActionCableProvider>
        </SessionProvider>
      </Hydrate>
    </QueryClientProvider>);
}
const __Next_Translate__Page__18f51d39a36__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__18f51d39a36__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  