import { createContext, useEffect, useState } from "react";
import { useSession } from "next-auth/react";

const ActionCableContext = createContext();

const ActionCableProvider = ({ children }) => {
  const { data: session } = useSession();
  const [CableApp, setCableApp] = useState({});

  const loadConsumer = async () => {
    const { createConsumer } = await import("@rails/actioncable");
    return createConsumer;
  };

  useEffect(() => {
    if (session?.user && session?.user?.accessToken && typeof window !== "undefined" && CableApp.cable === undefined) {
      loadConsumer().then((createConsumer) => {
        setCableApp({
          cable: createConsumer(`${process.env.NEXT_PUBLIC_API_WS_URL}?token=${session?.user?.accessToken}`),
        });
      });
    }
  }, [session]);

  return <ActionCableContext.Provider value={CableApp.cable}>{children}</ActionCableContext.Provider>;
};

export { ActionCableContext, ActionCableProvider };
