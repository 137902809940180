import Header from "./header";

const Layout = (props) => {
  return (
    <>
      <Header />
      <main id="root">{props.children}</main>
    </>
  );
};

export default Layout;
