import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NEXT_PUBLIC_ENV !== "development") {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_ENV,
    enabled: process.env.NEXT_PUBLIC_ENV !== "development",
    tracesSampleRate: 0.1,
  });
}
